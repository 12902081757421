import { Link } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from 'react'

import useBlogList from '../../hooks/useBlogList'
import useBlogPhotos from '../../hooks/useBlogPhotos'
import * as styles from './BlogCard.module.scss'

function BlogCard() {
  const data = useBlogPhotos()
  const blogList = useBlogList()
  const [modifiedBlogs, setModifiedBlogs] = useState(blogList)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (window.innerWidth === 360) {
        const modified = [...modifiedBlogs].map(blog => {
          return { ...blog, title: blog.title.replace(': ', ':\n') }
        })
        setModifiedBlogs(modified)
      }
    }
  }, [])

  return (
    <div className={styles.blog}>
      {modifiedBlogs.map((card, index) => {
        return (
          <div className={styles.blogItem} key={`blog-${index}`}>
            <div>
              {data[card.photoId] && (
                <GatsbyImage
                  image={data[card.photoId].childImageSharp.gatsbyImageData}
                  className={styles.img}
                  alt={card.altPhoto} />
              )}
            </div>
            <div className={styles.information}>
              <p className={styles.blogTitle}>
                <Link to={card.link}>{card.title}</Link>
              </p>
              <p className={styles.time}>{card.time}</p>
              <p className={styles.description}>{card.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default BlogCard
