import React from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import Blog from "../../components/Blog/Blog"
import Footer from "../../components/Footer/Footer"
import Layout from "../../components/Layout/Layout"
import NavbarContainer from "../../components/misc/NavbarContainer/NavbarContainer"

const BlogPage = () => {
  return (
    <ParallaxProvider>
      <Layout title="Blog | Be My Guest Vietnamilainen Ravintola | Jätkäsaari Helsinki">
        <NavbarContainer dark />
        <Blog />
        <Footer />
      </Layout>
    </ParallaxProvider>
  )
}

export default BlogPage
