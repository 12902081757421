import React from 'react'
import BlogCard from '../BlogCard/BlogCard'
import * as styles from './Blog.module.scss'

import BlogHeader from './BlogHeader'

function Blog() {
  return (
    <div className={styles.card}>
      <BlogHeader title="Be My Guest Food Blog" mainBlog={true} />
      <div className={styles.blogCard}>
        <BlogCard />
      </div>
    </div>
  )
}

export default Blog
